/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiBenefitItem, StrapiLegacyTweetGrid } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { spacingSets } from "@/tokens/configs/spacing_config";
import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { Divider } from "@/ui/atoms/divider";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Icon } from "@/ui/atoms/icon";
import { Link } from "@/ui/atoms/link";
import { Picture } from "@/ui/atoms/picture";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";
import { RichText } from "@/ui/molecules/rich_text";
import { LegacyBenefitsRow } from "@/ui/organisms/legacy_benefits_row";

import { buildStylesByBreakpoint } from "@/util/style_util";

interface LegacyTweetGridProps extends StrapiLegacyTweetGrid {
    className?: SerializedStyles;
    items?: Array<StrapiBenefitItem>;
}

export const LegacyTweetGrid: FunctionComponent<LegacyTweetGridProps> = (
    props,
) => {
    /**
     * Styles
     */
    const containerStyles = css(
        {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            position: "relative",
        },
        buildStylesByBreakpoint("rowGap", spacingSets.DefaultSectionMargin),
    );

    const ctaContainerStyles = css({
        display: "flex",
        justifyContent: "center",
        margin: "0 auto",
        maxWidth: "max-content",
    });

    const heroStyles = css(
        {
            alignItems: "center",
        },
        buildStylesByBreakpoint("gap", spacingSets.LegacyFeaturesHeroText),
    );

    const testimonialContentWrapperStyles = css(
        {
            alignItems: "center",
            boxSizing: "border-box",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            overflow: "hidden",
            pointerEvents: "auto",
            position: "relative",
            textAlign: "left",
            width: "100%",
        },
        buildStylesByBreakpoint("display", {
            extraSmall: "block",
            medium: "flex",
        }),
        buildStylesByBreakpoint("paddingLeft", {
            extraSmall: Spacing["spacing-8"],
            medium: Spacing["spacing-0"],
        }),
        buildStylesByBreakpoint("paddingRight", {
            extraSmall: Spacing["spacing-8"],
            medium: Spacing["spacing-0"],
        }),
    );

    const mobileTestimonialGridStyles = css(
        {
            alignSelf: "start",
            gridArea: "span 1 / span 1 / span 1 / span 1",
            gridAutoColumns: "1fr",
            gridColumnGap: Spacing["spacing-8"],
            gridRowGap: Spacing["spacing-8"],
            gridTemplateColumns: "1fr",
            gridTemplateRows: "auto",
        },
        buildStylesByBreakpoint("display", {
            extraSmall: "grid",
            medium: "none",
        }),
    );

    const desktopTestimonialGridStyles = css(
        {
            gridAutoColumns: "1fr",
            gridAutoFlow: "column",
            gridColumnGap: "2rem",
            gridRowGap: "2rem",
            gridTemplateColumns: "19.5rem 19.5rem 19.5rem 19.5rem 19.5rem",
            gridTemplateRows: "auto",
            whiteSpace: "nowrap",
        },
        buildStylesByBreakpoint("display", {
            extraSmall: "none",
            medium: "grid",
        }),
    );

    const mobileTestimonialListStyles = css({
        alignItems: "flex-start",
        cursor: "default",
        direction: "ltr",
        display: "flex",
        flexDirection: "column",
        gridAutoColumns: "1fr",
        gridColumnGap: Spacing["spacing-8"],
        gridRowGap: Spacing["spacing-8"],
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto",
    });

    const testimonialGridColumnStyles = css({
        alignItems: "flex-start",
        alignSelf: "start",
        cursor: "default",
        direction: "ltr",
        display: "flex",
        flexDirection: "column",
        gridArea: "span 1 / span 1 / span 1 / span 1",
        gridAutoColumns: "1fr",
        gridColumnGap: Spacing["spacing-8"],
        gridRowGap: Spacing["spacing-8"],
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto",
    });

    const testimonialItemStyles = css({
        backgroundColor: Colors["lighten-5"],
        borderRadius: BorderRadiuses["borderMedium"],
        boxShadow: `inset 0 0 0 1px ${Colors["lighten-5"]}`,
        boxSizing: "border-box",
        color: "white",
        cursor: "default",
        display: "flex",
        flexDirection: "column",
        gridAutoColumns: "1fr",
        gridColumnGap: Spacing["spacing-8"],
        gridRowGap: Spacing["spacing-8"],
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
        overflow: "hidden",
        padding: "1rem",
        placeContent: "start flex-start",
        placeItems: "stretch start",
        position: "relative",
        width: "100%",
    });

    const testimonialCustomInfoStyles = css({
        alignItems: "center",
        display: "flex",
        gridColumnGap: Spacing["spacing-3"],
        gridRowGap: Spacing["spacing-3"],
        position: "relative",
        whiteSpace: "normal",
        zIndex: 1,
    });

    const testimonialCustomerTextWrapperStyles = css({
        alignItems: "flex-start",
        display: "flex",
        flexFlow: "column wrap",
        gridColumnGap: Spacing["spacing-1"],
        gridRowGap: Spacing["spacing-1"],
    });

    const testimonialHeadshotStyles = css({
        borderRadius: BorderRadiuses["borderRound"],
        height: "auto",
        width: Spacing["spacing-12"],
    });

    const testimonialRichTextStyles = css(
        {
            position: "relative",
            whiteSpace: "normal",
            zIndex: 1,
        },
        {
            "& p:not(:last-child)": {
                marginBottom: Spacing["spacing-4"],
            },
        },
    );

    const benefitsRowContainerStyles = css({
        margin: "0 auto",
        maxWidth: "max-content",
        paddingTop: Spacing["spacing-18"],
        position: "relative",
        zIndex: 1,
    });

    const benefitCardStyles = css({
        alignItems: "flex-start",
        display: "flex",
        flex: "1 1 0",
        gap: Spacing["spacing-3"],
    });

    /**
     * Rendering
     */
    const renderHero = () => {
        return (
            <SubgridContainer
                className={heroStyles}
                columnSpan={{
                    extraSmall: "full",
                    medium: 8,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 3,
                }}
            >
                <GridColumn>
                    {props.Headline && (
                        <Text
                            fontSize="LegacyFeaturesHeroHeadline"
                            tag="h2"
                            textAlign="center"
                        >
                            {props.Headline}
                        </Text>
                    )}
                </GridColumn>

                {props.Subheadline && (
                    <GridColumn
                        columnSpan={{
                            extraSmall: "full",
                            medium: 6,
                        }}
                        columnStart={{
                            extraSmall: 1,
                            medium: 2,
                        }}
                    >
                        <RichText config="LegacyFeatureHero">
                            {props.Subheadline}
                        </RichText>
                    </GridColumn>
                )}
            </SubgridContainer>
        );
    };

    const renderMobileTweetGrid = () => {
        return (
            <div css={mobileTestimonialGridStyles}>
                <div css={mobileTestimonialListStyles}>
                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Nat Friedman profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Nat_Friedman_Profile_Pic_1efe1773b2.jpg"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Nat Friedman
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    CEO @ Github
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                Finally, innovation in terminals!{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>
                            </Text>
                        </div>
                    </div>

                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Katerina Skroumpelou profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Katerina_Skroumpelou_Profile_Pic_6d36049ba2.png"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Katerina Skroumpelou
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    @googledevexpert
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                Every day I love{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>{" "}
                                more. The autocompletion, the fzf of my past
                                commands, the blocks of command outputs.
                                It&apos;s just so efficient!
                            </Text>
                        </div>
                    </div>

                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Aman Virk profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Aman_18a179c69c.jpg"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Aman Virk
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Building @adonisframework
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                Giving{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>{" "}
                                a try. It is blazing fast and indeed feels like
                                a modern terminal.
                            </Text>
                        </div>
                    </div>

                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Jon Meyers profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Jon_d608ac26e8.jpg"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Jon Meyers
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Developer Advocate @supabase
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                Just started using{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>{" "}
                                as my main terminal!🎉
                            </Text>

                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                It is just so good!💯{" "}
                            </Text>

                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                I don&apos;t know why I just accepted that one
                                of the main tools I use every day as a developer
                                was so bad!!
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderDesktopTweetGrid = () => {
        return (
            <div css={desktopTestimonialGridStyles}>
                <div css={testimonialGridColumnStyles} role="list">
                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Ash Allen profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Ash_Allen_Profile_Pic_563cd3d330.jpeg"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Ash Allen
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Engineer
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                I don&apos;t think you&apos;ll regret it one
                                bit.{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>{" "}
                                is really cool and I&apos;ve completely switched
                                to using it now
                            </Text>
                        </div>
                    </div>

                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Chau Tran profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Chau_Tran_Profile_Pic_ea67c9b753.png"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Chau Tran
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Engineer @nrwl_io
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>{" "}
                                editor is a game changer for my terminal
                                workflow. I love how I can navigate the terminal
                                editor just like a code editor with all the
                                Move/Move-Select keymap. Great job!
                            </Text>
                        </div>
                    </div>

                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Srijan Paul profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Srijan_Paul_Profile_Pic_84c27fb3bd.png"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Srijan Paul
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Engineer
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>
                                is early access and yet absolutely mindblowing!
                                Became my new favorite terminal overnight. Best
                                features from all the different shells I&apos;ve
                                used and a modern look UI + keybindings. Could
                                not have asked for more.
                            </Text>
                        </div>
                    </div>
                </div>

                <div css={testimonialGridColumnStyles} role="list">
                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Nat Friedman profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Nat_Friedman_Profile_Pic_1efe1773b2.jpg"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Nat Friedman
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    CEO @ Github
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                Finally, innovation in terminals!{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>
                            </Text>
                        </div>
                    </div>

                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Katerina Skroumpelou profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Katerina_Skroumpelou_Profile_Pic_6d36049ba2.png"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Katerina Skroumpelou
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    @googledevexpert
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                Every day I love{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>{" "}
                                more. The autocompletion, the fzf of my past
                                commands, the blocks of command outputs.
                                It&apos;s just so efficient!
                            </Text>
                        </div>
                    </div>

                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Aman Virk profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Aman_18a179c69c.jpg"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Aman Virk
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Building @adonisframework
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                Giving{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>{" "}
                                a try. It is blazing fast and indeed feels like
                                a modern terminal.
                            </Text>
                        </div>
                    </div>
                </div>

                <div css={testimonialGridColumnStyles} role="list">
                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Jon Meyers profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Jon_d608ac26e8.jpg"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Jon Meyers
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Developer Advocate @supabase
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                Just started using{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>{" "}
                                as my main terminal!🎉
                            </Text>

                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                It is just so good!💯{" "}
                            </Text>

                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                I don&apos;t know why I just accepted that one
                                of the main tools I use every day as a developer
                                was so bad!!
                            </Text>
                        </div>
                    </div>

                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Ben Holmes profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Frame_1000002402_1_1c0910a2dd.png"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Ben Holmes
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    @astrodotbuild
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                Just saying, I heckin love{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>
                            </Text>

                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                🚀Stupid fast boot times View history with arrow
                                keys (no mindless ⬆️⬆️⬆️...)
                            </Text>

                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                ✂️ Each command is a block for easy scrolling,
                                error highlighting, and copy / past-ability
                            </Text>

                            <Text fontSize="LegacyTweetGridTweet">
                                💻 Intellisense for npm and git
                            </Text>
                        </div>
                    </div>

                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Kevin | kevcode.sol profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Kevi_a99460f8fb.jpg"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Kevin | kevcode.sol
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Engineer
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                holy{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>{" "}
                                is the best terminal I&apos;ve ever used
                            </Text>
                        </div>
                    </div>
                </div>

                <div css={testimonialGridColumnStyles} role="list">
                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="bleachedsleet.eth profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Bleach_ee59ee79a9.jpg"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    ✦ bleachedsleet.eth ✦
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    @getprotocol
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                2/{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>{" "}
                                A new terminal developed in Rust, I instantly
                                fell in love when I tried it. There&apos;s just
                                no going back from the incredible navigation
                                workflows you can achieve with this tool. If the
                                terminal had been invented today, this what we
                                would get. Bravo!
                            </Text>
                        </div>
                    </div>

                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Asbjørn B profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Asbjorn_B_Profile_Pic_8cb27ec36a.jpeg"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Asbjørn B
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Engineer
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>{" "}
                                is really the terminal i&apos;ve been missing,
                                since my switch to OSX!
                            </Text>

                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                Kudos to everyone involved, for an awesome
                                terminal!
                            </Text>
                        </div>
                    </div>

                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Sylvain profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Frame_1000002402_8bb3a6c041.png"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Sylvain (:seel-vahn)
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    CTO
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                Just received my invite to{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>{" "}
                                and I already love the developer experience and
                                the speed of that terminal. A breath of fresh
                                air for me and my MacBook 2015!👇

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://t.co/mfLfpZsko5"
                                    interactionType="underline-disappear"
                                >
                                    https://warp.dev
                                </Link>
                            </Text>
                        </div>
                    </div>
                </div>

                <div css={testimonialGridColumnStyles} role="list">
                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Mr. Ninja profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Mr_4d1c6afb1e.jpg"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Mr. Ninja
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Engineer
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                just got my invite for{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>{" "}
                                and holy crap it has been worth the wait. What
                                an incredible product! thank you for letting me
                                in.
                            </Text>
                        </div>
                    </div>

                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Zack profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Zack_Profile_Pic_Warp_f84405199e.jpeg"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Zack
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Engineer
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                Just started using{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>
                                &apos;s terminal... so far it&apos;s been a way
                                better experience than using iTerm2
                            </Text>
                        </div>
                    </div>

                    <div css={testimonialItemStyles} role="listitem">
                        <div css={testimonialCustomInfoStyles}>
                            <Picture
                                alternativeText="Raul profile picture"
                                className={testimonialHeadshotStyles}
                                height={60}
                                url="https://leading-bell-3e1c02e64d.media.strapiapp.com/Raul_Profile_Pic_bd0c100ac3.png"
                                width={60}
                            />

                            <div css={testimonialCustomerTextWrapperStyles}>
                                <Text
                                    color="lighten-90"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Raul
                                </Text>

                                <Text
                                    color="lighten-70"
                                    fontSize="LegacyTweetGridTweet"
                                >
                                    Engineer
                                </Text>
                            </div>
                        </div>

                        <div css={testimonialRichTextStyles}>
                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                I got beta access to{" "}

                                <Link
                                    fontSize="LegacyTweetGridTweet"
                                    href="https://twitter.com/warpdotdev"
                                    interactionType="underline-disappear"
                                >
                                    @warpdotdev
                                </Link>
                                .
                            </Text>

                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                I have to say that I&apos;m really impressed
                                with it.
                            </Text>

                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                Despite the beauty, it&apos;s super fast, and
                                the out-of-box auto-suggestion is delightful to
                                use.
                            </Text>

                            <Text
                                color="lighten-90"
                                fontSize="LegacyTweetGridTweet"
                            >
                                Pleasant experience. Well done, peps!
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderHardCodedTweets = () => {
        return (
            <div css={testimonialContentWrapperStyles}>
                {renderMobileTweetGrid()}

                {renderDesktopTweetGrid()}
            </div>
        );
    };

    return (
        <section css={containerStyles}>
            <GridContainer>
                {(props.Headline || props.Subheadline) && renderHero()}
            </GridContainer>

            {renderHardCodedTweets()}

            {props.items && (
                <div css={benefitsRowContainerStyles}>
                    <Divider
                        className={css({
                            left: 0,
                            position: "absolute",
                            right: 0,
                            top: 0,
                        })}
                    />

                    <LegacyBenefitsRow items={props.items} />
                </div>
            )}

            {props.Benefits && (
                <GridContainer>
                    <GridColumn
                        columnSpan={{
                            extraSmall: "full",
                            large: 8,
                        }}
                        columnStart={{
                            extraSmall: 1,
                            large: 3,
                        }}
                    >
                        <Divider />

                        <div
                            css={css(
                                {
                                    display: "flex",
                                    gap: Spacing["spacing-8"],
                                },
                                buildStylesByBreakpoint("flexDirection", {
                                    extraSmall: "column",
                                    medium: "row",
                                }),
                                buildStylesByBreakpoint("marginTop", {
                                    extraSmall: Spacing["spacing-6"],
                                    large: Spacing["spacing-8"],
                                }),
                            )}
                        >
                            {props.Benefits.map((_benefit) => (
                                <div
                                    css={benefitCardStyles}
                                    key={`benefit::${_benefit.id}`}
                                >
                                    {_benefit.Icon && (
                                        <Icon
                                            className={css({
                                                padding: Spacing["spacing-2"],
                                            })}
                                            color="lighten-90"
                                            renderContainer={true}
                                            slug={_benefit.Icon}
                                        />
                                    )}

                                    <div>
                                        <Text
                                            fontWeight="medium"
                                            themeKey="textEmphasis"
                                        >
                                            {_benefit.Headline}
                                        </Text>

                                        <Text>{_benefit.Description}</Text>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </GridColumn>
                </GridContainer>
            )}

            {props.CTA && (
                <GridContainer>
                    <GridColumn className={ctaContainerStyles}>
                        <Button
                            fontSize="CtaDefault"
                            href={props.CTA.URL}
                            marginTop="PricingMatrixStickyNavMarginTop"
                            variant="secondary"
                        >
                            {props.CTA.Text}
                        </Button>
                    </GridColumn>
                </GridContainer>
            )}
        </section>
    );
};
