/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiBenefitItem, StrapiIcon } from "@/types/strapi";
import { GridColumnCount, GridColumnSpan } from "@/types/tokens/grid";

import { spacingSets } from "@/tokens/configs/spacing_config";
import { Spacing } from "@/tokens/spacing";

import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Icon } from "@/ui/atoms/icon";
import { Text } from "@/ui/atoms/text";

import { buildStylesByBreakpoint } from "@/util/style_util";
import { convertToRem } from "@/util/ui_util";

interface LegacyBenefitsRowProps {
    className?: SerializedStyles;
    items: Array<StrapiBenefitItem>;
}

export const LegacyBenefitsRow: FunctionComponent<LegacyBenefitsRowProps> = (
    props,
) => {
    /**
     * Globals
     */
    const isNested = [2, 4].includes(props.items.length);

    /**
     * Styles
     */
    const containerStyles = css(
        {
            alignItems: "center",
        },
        buildStylesByBreakpoint("rowGap", spacingSets.ProductHeroBlocks),
    );

    const benefitItemContainerStyles = css(
        {
            display: "flex",
        },
        buildStylesByBreakpoint("gap", spacingSets.LegacyBenefitItem),
        buildStylesByBreakpoint("flexDirection", {
            extraSmall: "column",
            small: "row",
        }),
    );

    const benefitItemStyles = css({
        alignItems: "flex-start",
        columnGap: Spacing["spacing-3"],
        display: "flex",
        flex: "1 1 0",
    });

    const iconStyles = buildStylesByBreakpoint(
        "marginBottom",
        spacingSets.LegacyBenefitItemIcon,
    );

    const textContainerStyles = css(
        {
            display: "flex",
            flexDirection: "column",
            paddingTop: convertToRem(7), // One-off value to align to icon
        },
        buildStylesByBreakpoint("gap", spacingSets.LegacyBenefitItemText),
    );

    /**
     * Rendering
     */
    const renderBenefitItem = (
        benefitItem: StrapiBenefitItem,
        index: number,
    ) => {
        const { Description, Headline, Icon: benefitIcon } = benefitItem;

        return (
            <div
                css={benefitItemStyles}
                key={`legacy-benefit-item::${Headline}::${index}`}
            >
                {Icon && (
                    <Icon
                        className={iconStyles}
                        color="lighten-70"
                        fontSize="LegacyBenefitItemHeadline"
                        renderContainer={true}
                        slug={benefitIcon as StrapiIcon}
                    />
                )}

                <div css={textContainerStyles}>
                    {Headline && (
                        <Text
                            fontSize="LegacyBenefitItemHeadline"
                            fontWeight="medium"
                            tag="h2"
                        >
                            {Headline}
                        </Text>
                    )}

                    {Description && (
                        <Text
                            fontSize="LegacyBenefitItemDescription"
                            themeKey="textPrimary"
                        >
                            {Description}
                        </Text>
                    )}
                </div>
            </div>
        );
    };

    const renderBenefitItems = () => {
        const columnStart: GridColumnCount = isNested
            ? {
                  extraSmall: 1,
                  medium: 2,
                  large: 3,
              }
            : 1;

        const columnSpan: GridColumnSpan = isNested
            ? {
                  extraSmall: "full",
                  medium: 10,
                  large: 8,
              }
            : "full";

        return (
            <GridColumn
                className={benefitItemContainerStyles}
                columnSpan={columnSpan}
                columnStart={columnStart}
            >
                {props.items.map((_benefitItem, _index) =>
                    renderBenefitItem(_benefitItem, _index),
                )}
            </GridColumn>
        );
    };

    return (
        <GridContainer className={containerStyles}>
            {renderBenefitItems()}
        </GridContainer>
    );
};
